import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/users.png';

const Content = () => {
  return (
    <div>
      <p>
        It is easier to manage users in your team now. Owners and admins can delete users directly
        from a list. Also, invitations are now grouped in a seperated list with options to resend or
        cancel.
      </p>
      <ImageModal src={img} alt="Management" title="Manage users in your team" />
      <p className="mt-3" />
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Better team management',
  url: '/blog/team-management/',
  description: 'More options to control team and invitations',
  author,
  img,
  date: '2019-06-30',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
